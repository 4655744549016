@import '../../styles/utils.scss';

.el {
  height: 100vh;
  min-height: 500px;
  background: #272560;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: url(/images/404bg.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  @media screen and (max-width:991px) and (orientation:landscape){
      min-height:unset;
  }
  h1 {
    font-weight: 600;
    font-size: 12vw;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.07em;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
    @media screen and (min-width: 1440px) {
      font-size: 230px;
    }
    @include mobile {
      font-size: 30vw;
    }
    @media screen and (max-width:991px) and (orientation:landscape){
      font-size:10vw;
    }
  }
  h3 {
    @media screen and (max-width:991px) and (orientation:landscape){
      font-size:18px;
    }
  }
  .button {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 35px;
    color: #fff;
    background: $color-orange;
    border-radius: 40px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: 0.05em;
    margin-top: 15px;
    @media screen and (max-width:991px) and (orientation:landscape){
      font-size:12px;
    }
  }
  .overlayImage {
    pointer-events: none;
    position: absolute;
    bottom: 0;
    right: 0;
    line-height: 0;
    width: 90%;
    max-width: 812px;
    opacity: 0.8;
    svg {
      width: 100%;
      height: auto;
    }
    @media screen and (max-width:991px) and (orientation:landscape){
      width: 50%;
    }
  }
}
